import React, { useState, useEffect } from 'react';
import Slider from './NetflixSlider';
import UserService from '../services/user.service';
import './home.component.css';
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";



const Home = () => {

  const { id } = useParams();

  const [contenido, setContenido] = useState();

  const navigate = useNavigate(); // Usar el hook useNavigate


  useEffect(() => {
     
        UserService.loguearcompetencia({id:id}).then(
        response => {
          setContenido(response.data);

          },
        error => {
          navigate('/'); 

    /*       console.log(
            (error.response && error.response.data) ||
            error.message ||
            error.toString()
          ); */
        }
      );


    }, [id]);

  return (
    <div>
      <div>
      <header>
        <nav className="navbar">
          <div className="navbar__brand">
            <img src="../img/logo_escuela_aye_sd.png" alt="logo" className="brand__logo" />
          </div>
            <div className="profile2-text-container">
            <h2 className="tituloinicio">Sistema Oficial de Votaciones</h2>
          <span className=" subtituloinicio">Bienvenido {contenido && contenido.name +" "+ contenido.apellido}</span>

     
            
         
     
          </div>
        </nav>
      </header>
      </div>
  {contenido &&contenido.peliculas && contenido.peliculas.length > 0 ?
      <div className="carruselnetflix bannercomp "  >
   
        <div className="container">
        <br></br>

          <h3 className="tituloinicio">{contenido.titulo}</h3>
          <h3 className="subtituloinicio">A continuación estos son los títulos participantes:</h3>
        </div>

      <Slider comp={contenido}>

        
          {contenido.peliculas.map(movie => (
            <Slider.Item className="container" movie={movie} key={movie.id} comp={contenido}    >item1</Slider.Item>
          ))}

        </Slider>
 </div>:<div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9999,
      }}
    >
      <div
        style={{
          backgroundColor: '#fff',
          padding: '20px 40px',
          borderRadius: '5px',
          boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
          textAlign: 'center',
        }}
      >
        <h2 style={{ margin: '0 0 10px' }}>
<i className="fas fa-spinner fa-spin" />
    
</h2>
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>}
      <section className="hero">
        <div className="hero__bg__overlay" />
      </section>





          
    </div>
  );
};

export default Home;