import React from 'react';
import cx from 'classnames';
import SliderContext from './context'
import ShowDetailsButton from './ShowDetailsButton'
import Mark from './Mark'
import './Item.scss'

import ImageWithPreloader from './ImageWithPreloader';



const Item = ({ movie }) => (
  <SliderContext.Consumer>
    {({ onSelectSlide, currentSlide, elementRef }) => {
      
      const isActive = currentSlide && currentSlide.id === movie.id;

      const scrollToBottom = () => {
        window.scrollTo({
          top: document.documentElement.scrollHeight,
          behavior: 'smooth'
        });
      };


      return (
        <div onClick={() => {onSelectSlide(movie);scrollToBottom();}


          


        }
          ref={elementRef}
          className={cx('item', {
            'item--open': isActive,
          })}
        >
       <ImageWithPreloader src={movie.imgportada} alt="Descripción de la imagen" />


         
          <ShowDetailsButton onClick={() => {onSelectSlide(movie);scrollToBottom();}} />
         
         
          {isActive && <Mark />}
          
        </div>
      );
    }}
  </SliderContext.Consumer>
);

export default Item;
