import React, { useState,useEffect } from 'react';
import UserService from './services/user.service';
import { join, set } from 'lodash';

const Paneladm = () => {
  const [formData, setFormData] = useState({
    email: '',
    comp: '1',
    mensaje: '',
    asunto: '',
    uid: ''
  });

  const [competencias,setcompetencias] = useState(null);

  const [confirmaciones, setConfirmaciones] = useState([]);

  const [loading, setLoading] = useState(false);

  const [consulta, setConsulta] = useState(null);

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setConfirmaciones([]);

    if(formData.email && formData.comp && formData.mensaje){

    const emails = formData.email.split(',').map(email => email.trim());
    const confirmaciones = [];
    setLoading(true);
    for (const email of emails) {
      try {
        const response = await enviarlink({ ...formData, email });
        confirmaciones.push("Enviando a: "+email);
        confirmaciones.push("Su enlace es: "+response.data);
      } catch (error) {
        console.error(error);
        confirmaciones.push({ error: error.message });
      }
    }
  
    setConfirmaciones(confirmaciones);
    setLoading(false);
}else{

alert("Verifica que seleccionaste una competencia y que ingresaste correctamente el resto de la informacion");
setLoading(false);

}



  };
  const enviarlink = (data) => {

    return UserService.enviarlinkvotar(data);
  };




  useEffect(() => {

    UserService.getPublicVotaciones()
      .then(response => {
        console.log("competencias:",response.data );
        setcompetencias(response.data)
      })
      .catch(error => {
        console.error(error);
      });

 




  }, []);

  const consultarVotos = (id)=>{


    UserService.getShowvotos(id)
    .then(response => {
      console.log("consulta:",response.data );
      setConsulta(response.data)
    })
    .catch(error => {
      console.error(error);
    });

  }

  return (
  <div>
    <div><botton>Enviar</botton></div>
    <div className="container d-flex justify-content-center align-items-center vh-100">
  <form className="col-12 col-md-8 col-lg-8 bg-white p-4 rounded shadow" onSubmit={handleSubmit}>
    <h2 className="text-center mb-4">Panel Administrador</h2>
   
        
    
    <h2 className="text-center mb-4">Enviar formularios</h2>

    <div className="form-group">
      <label htmlFor="email">Email: separados por comas (,)</label>
      <input
        type="text"
        id="email"
        name="email"
        className="form-control"
        value={formData.email}
        onChange={handleInputChange}
        required
      />
    </div>
   


    <div className="form-group">
      <label htmlFor="asunto">Asunto:</label>
      <textarea
        id="asunto"
        name="asunto"
        className="form-control"
        value={formData.asunto}
        onChange={handleInputChange}
        required
      ></textarea>
    </div>
  
    <div className="form-group">
      <label htmlFor="mensaje">Mensaje:</label>
      <textarea
        id="mensaje"
        name="mensaje"
        className="form-control"
        value={formData.mensaje}
        onChange={handleInputChange}
        required
      ></textarea>
    </div>

    <div className="form-group">
      <label htmlFor="comp">Competencia:</label>
      <select
        defaultValue={1} 
        id="comp"
        name="comp"
        className="form-control"
        value={formData.comp}
        onChange={handleInputChange}
        required
      >
        <option unselectable='true' >Seleccione una competencia</option>
        {competencias && competencias.map((item) => (
          <option key={item.id} value={item.id}>
            {item.titulo}
          </option>
        ))}
      </select>
    </div>

    <div className="text-center">
      <button type="submit" className="btn btn-primary" disabled={loading}>
        Enviar
      </button>
    </div>
    <br />
    {loading && <p className='btn btn-info disabled  justify-content-center'>Enviando Mensajes...</p>}

  </form>

</div>


<div className="container d-flex justify-content-center align-items-center vh-100">
  <form className="col-12 col-md-8 col-lg-8 bg-white p-4 rounded shadow" onSubmit={handleSubmit}>
    <h2 className="text-center mb-4">Panel Administrador</h2>
    <div className="form-group">
      <label htmlFor="comp">Competencia:</label>
      <select
        defaultValue={1} 
        id="comp"
        name="comp"
        className="form-control"
        value={formData.comp}
        onChange={handleInputChange}
        required
      >
        <option unselectable='true' >Seleccione una competencia</option>
        {competencias && competencias.map((item) => (
          <option key={item.id} value={item.id}>
            {item.titulo}
          </option>
        ))}
      </select>
    </div>
    <div className="text-center">
 <button className="btn btn-info" type="button" onClick={()=>consultarVotos(formData.comp)}>Consultar Resultados</button>

 <br />
 {consulta && <table className='table table-dark py-5 my-3'>{consulta.conteo_peliculas.map((item)=><tr><td>{item.tituloes}</td> <td>{item.conteo} Votos</td></tr>)} 
 <tr><strong>Total de Votos Realizados:</strong>{consulta.total_votos}</tr>
 </table>}
 
 </div>
 </form>

</div>

<div className="container">
  {confirmaciones &&
    confirmaciones.map((confirmacion, index) => (
      <div key={index} className="alert alert-info">
       <p> {JSON.stringify(confirmacion)}</p>
      </div>
    ))}
</div></div>
  );
};

export default Paneladm;