import React, { useState, useEffect } from 'react';
import UserService from '../../services/user.service';
import { update } from 'lodash';

const Addpelicula = () => {


  const [competencias, setCompetencias] = useState([]);
  const [selectcomp, setSelectcomp] = useState(null);

  const [peliculas, setPeliculas] = useState([]);

  const [consulta, setConsulta] = useState(null);
  const [loading, setLoading] = useState(false);
  const [editar, seteditar] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [addnew, setaddnew] = useState(false);
  const [formData, setFormData] = useState({
    tituloes: '',
    tituloeng: '',
    pais: '',
    fechacrea: '',
    fechaestreno: '',
    censuratipo: '',
    linktrailer: '',
    linkpelicula: '',
    password: '',
    genero: '',
    duracion: '',
    operaprima: false,
    director: '',
    guionista: '',
    produccion: '',
    fichatecnica: '',
    sinopsis: '',
    reparto: '',
    adjuntosecreto: '',
    imgportada: '',
    imagenes: '',
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleEstadoChange = async (e) => {
    try {
      const nuevoEstado = e.target.value;

      const data = { estado: nuevoEstado };
  
      const response = await UserService.editcomp(consulta.competencia.id, data);
      
      // No necesitas llamar a response.json(), ya que Axios lo maneja automáticamente
      const result = response.data;
  
      // Axios lanza un error si la respuesta no es exitosa, así que no necesitas este chequeo
      setConsulta((prevState) => ({
        ...prevState,
        competencia: {
          ...prevState.competencia,
          estado: nuevoEstado,
        },
      }));
      console.log('Estado de la competencia actualizado exitosamente');
    } catch (error) {
      console.error('Error al actualizar el estado de la competencia:', error.response?.data || error.message);
    }
  };





  const updateForm = async (e) => {
    try {

      e.preventDefault();

      const response = await UserService.editcomp(consulta.competencia.id, formData);
      
      // No necesitas llamar a response.json(), ya que Axios lo maneja automáticamente
      const result = response.data;
  
 
      console.log('competencia actualizado exitosamente');
      window.location.reload(false)
    } catch (error) {
      console.error('Error al actualizar la competencia:', error.response?.data || error.message);
    }
  };












  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.files[0]
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();

    // Agregar datos del formulario a FormData
    Object.keys(formData).forEach(key => {
      formDataToSend.append(key, formData[key]);
    });
    console.log('Datos del formulario:', formData);


    try {
      await UserService.addcompetencia(formDataToSend);
      alert('Competencia creada exitosamente');
      window.location.reload(false)

      setFormData({
        titulo: '',
        inicio: '',
        cierre: '',
        estado: '',
        resumen: '',
        descripcion: '',
        bases: '',
        adjunto: null,
        imgportada: null,
        imgpie: null,
        tipo: ''
      });
    } catch (error) {
      console.error(error);
      alert('Error al crear la competencia');
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0]; // Devuelve solo la parte de la fecha
  };

  const consultarVotos = async (id) => {
    setLoading(true);
    try {
      const response = await UserService.getShowvotos(id);
      setConsulta(response.data);
console.log(response.data)
    } catch (error) {
      console.error(error);
      alert('Error al consultar los votos');
    } finally {
      setLoading(false);
    }
  };


  const asignarcomp = async (id) => {
    setLoading(true);
    try {
      const response = await UserService.getShowvotos(id);
      setConsulta(response.data);
console.log(response.data)
    } catch (error) {
      console.error(error);
      alert('Error al consultar los votos');
    } finally {
      setLoading(false);
    }
  };





  useEffect(() => {
    const fetchCompetencias = async () => {
      try {
        const response = await UserService.allpelis();
        console.log(response.data)
        setPeliculas(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchPeliculas = async () => {
      try {
        const response = await UserService.getPublicVotaciones();
        console.log(response.data)
        setCompetencias(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchPeliculas();
    fetchCompetencias();
  }, []);

  return (
    <div className="container">
        
      

                  {addnew ||editar?

        <div className="container">
          <div className="card shadow">
          <div className="card-header bg-secondary text-white">
              <h2 className="mb-0">Formulario:</h2>
            </div>
            <div className="card-body text-white">
              
      <form onSubmit={handleSubmit} className="bg-dark p-4 rounded">
        <div className="row">
          <div className="col-md-6">
            <div className="mb-3">
              <label className="form-label text-white">Título en Español:</label>
              <input type="text" className="form-control" name="tituloes" value={formData.tituloes} onChange={handleChange} required />
            </div>
            <div className="mb-3">
              <label className="form-label text-white">Título en Inglés:</label>
              <input type="text" className="form-control" name="tituloeng" value={formData.tituloeng} onChange={handleChange} required />
            </div>
            <div className="mb-3">
              <label className="form-label text-white">País:</label>
              <input type="text" className="form-control" name="pais" value={formData.pais} onChange={handleChange} required />
            </div>
            <div className="mb-3">
              <label className="form-label text-white">Fecha de Creación:</label>
              <input type="date" className="form-control" name="fechacrea" value={formData.fechacrea} onChange={handleChange} required />
            </div>
            <div className="mb-3">
              <label className="form-label text-white">Tipo de Censura:</label>
              <input type="text" className="form-control" name="censuratipo" value={formData.censuratipo} onChange={handleChange} required />
            </div>
            <div className="mb-3">
              <label className="form-label text-white">Género:</label>
              <input type="text" className="form-control" name="genero" value={formData.genero} onChange={handleChange} required />
            </div>
            <div className="mb-3">
              <label className="form-label text-white">Duración:</label>
              <input type="text" className="form-control" name="duracion" value={formData.duracion} onChange={handleChange} required />
            </div>
            <div className="mb-3">
              <label className="form-label text-white">Director:</label>
              <input type="text" className="form-control" name="director" value={formData.director} onChange={handleChange} required />
            </div>
            <div className="mb-3">
              <label className="form-label text-white">Guionista:</label>
              <input type="text" className="form-control" name="guionista" value={formData.guionista} onChange={handleChange} required />
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-3">
              <label className="form-label text-white">Fecha de Estreno:</label>
              <input type="date" className="form-control" name="fechaestreno" value={formData.fechaestreno} onChange={handleChange} required />
            </div>
            <div className="mb-3">
              <label className="form-label text-white">Link del Trailer:</label>
              <input type="url" className="form-control" name="linktrailer" value={formData.linktrailer} onChange={handleChange} required />
            </div>
            <div className="mb-3">
              <label className="form-label text-white">Link de la Película:</label>
              <input type="url" className="form-control" name="linkpelicula" value={formData.linkpelicula} onChange={handleChange} required />
            </div>
            <div className="mb-3">
              <label className="form-label text-white">Contraseña:</label>
              <input type="password" className="form-control" name="password" value={formData.password} onChange={handleChange} required />
            </div>
            <div className="mb-3">
              <label className="form-label text-white">Ópera Prima:</label>
              <input type="checkbox" className="form-check-input" name="operaprima" checked={formData.operaprima} onChange={handleChange} />
              <label className="form-check-label text-white">Sí</label>
            </div>
            <div className="mb-3">
              <label className="form-label text-white">Producción:</label>
              <input type="text" className="form-control" name="produccion" value={formData.produccion} onChange={handleChange} required />
            </div>
            <div className="mb-3">
              <label className="form-label text-white">Ficha Técnica:</label>
              <textarea className="form-control" name="fichatecnica" value={formData.fichatecnica} onChange={handleChange} required />
            </div>
            <div className="mb-3">
              <label className="form-label text-white">Sinopsis:</label>
              <textarea className="form-control" name="sinopsis" value={formData.sinopsis} onChange={handleChange} required />
            </div>
            <div className="mb-3">
              <label className="form-label text-white">Reparto:</label>
              <input type="text" className="form-control" name="reparto" value={formData.reparto} onChange={handleChange} required />
            </div>
            <div className="mb-3">
              <label className="form-label text-white">Imagen de Portada:</label>
              <input type="url" className="form-control" name="imgportada" value={formData.imgportada} onChange={handleChange} required />
            </div>
            <div className="mb-3">
              <label className="form-label text-white">Imágenes:</label>
              <input type="url" className="form-control" name="imagenes" value={formData.imagenes} onChange={handleChange} />
            </div>
            <div className="mb-3">
              <label className="form-label text-white">Adjuntos Secretos:</label>
              <input type="file" className="form-control" name="adjuntosecreto" onChange={handleChange} />
            </div>
          </div>
        </div>
        <button type="submit" className="btn btn-primary">Enviar</button>
      </form>
            </div>
          </div>
        </div>:  <div className="container">
          <div className="">
            <div className="card-header bg-secondary text-white">
              <h2 className="mb-0">Peliculas:</h2>
            </div>
            
            <div className="card-body">
            <button className="btn btn-primary"  onClick={()=> setaddnew(true)} ><i className="fa fa-plus" aria-hidden="true"></i><span> </span>
            Agregar Nuevo                </button>
              <div className="form-group">
                  {peliculas.map((props) => (
                 <div className="movie-card2 py-4">
                 <img src={props.imgportada} alt={props.tituloes} className="col-3 movie-poster float-left" />
                 <div className="movie-info">
                   <h2>{props.tituloes}</h2>
                   <p><strong>Director:</strong> {props.director}</p>
                   <p><strong>Guionista:</strong> {props.guionista}</p>
                   <p><strong>Producción:</strong> {props.produccion}</p>
                   <p><strong>Reparto:</strong> {props.reparto}</p>

                   <p><strong>Año:</strong> {props.fechacrea}</p>
                   <p><strong>Estreno:</strong> {props.fechaestreno}</p>
                   <p><strong>Clasificación:</strong> {props.censuratipo}</p>

                   <p><strong>País:</strong> {props.pais}</p>
                   <p><strong>Sinopsis:</strong> {props.sinopsis}</p>
                 </div>
                 <div className="row mb-3">
  
</div>
<div className="row container">
  <div className="col-md-8">
    <select
      id="comp"
      className="form-control"
      onChange={(e) => setSelectcomp(e.target.value)}
    >
      <option value="">Seleccionar competencia</option>
      {competencias.map((item) => (
        <option key={item.id} value={item.titulo}>
          {item.titulo}
        </option>
      ))}
    </select>
  </div>
  <div className="col-md-4">
    <button className="btn btn-primary w-100" onClick={()=>setShowModal({id:props.id,tituloes:props.tituloes})}>Agregar a Competencia</button>
  </div>
  
</div>


               </div>
                  ))}
              </div>
              {loading && <p>Cargando resultados...</p>}
              {consulta && (
                <div className=" card-body form-group bg-secondary text-white">
                                  <h3 >Datos Competencia</h3>
                    <div >
                    <p >{consulta.competencia.titulo}</p>

                  
<strong>Inicio Votaciones:</strong>

<p>{formatDate(consulta.competencia.inicio)}</p>
<strong>Cierre Votaciones:</strong>

<p>{formatDate(consulta.competencia.cierre)}</p>
<label htmlFor="estado"><strong>Estado Actual:</strong></label>
                  <select
  id="estado"
  name="estado"
  className="form-control"
  value={consulta.competencia.estado}
  onChange={handleEstadoChange}
>
  <option value="publico">Publico</option>
  <option value="oculto">Oculto</option>
  <option value="cerrado">Cerrado</option>
</select>

<div>
  <br></br>
<button   className=" btn btn-primary " onClick={()=>{setFormData(consulta.competencia);seteditar(true)}}
><i className="fa fa-pencil" aria-hidden="true"></i><span> </span>
Editar Competencia</button>
</div>
                </div>
                <br></br>
                <h3 className='card-header bg-secondary text-white py-10'>Resultados:</h3>

                                    <table className="table table-dark ">
  <thead>
    <tr>
      <th>Posición</th>
      <th>Pelicula</th>
      <th>Votos Recibidos</th>
    </tr>
  </thead>
  <tbody>
    {consulta.conteo_peliculas.map((item, index) => (
      <tr key={index}>
        <td># {index + 1}</td>
        <td>{item.tituloes}</td>
        <td> [<strong> {item.conteo}</strong> ] Votos</td>
      </tr>
    ))}
    <tr>
      <td colSpan="3" className="text-center">
        <strong>Total de Votos Realizados: {consulta.total_votos}</strong>
      </td>
    </tr>
  </tbody>
</table>

</div>

              )}
            </div>
          </div>



        </div>
         }


    {/* Modal de Confirmación */}
    <div className={`modal  ${showModal ? 'show' : ''}`} style={{ display: showModal ? 'block' : 'none' }} tabIndex="-1" role="dialog">
  <div className="modal-dialog" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">Confirmación</h5>
        <button type="button" className="close" onClick={() => setShowModal(false)}>
          <span>&times;</span>
        </button>
      </div>
      <div className="modal-body">
       <p><strong>Agregar la pelicula:</strong>{showModal.tituloes} </p>
        <p><strong>A la Competencia:</strong>{selectcomp}</p>

      </div>

      <div className="modal-footer">
      <p className=''>¿Estás seguro ?</p>

        <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>Cancelar</button>
        <button type="button" className="btn btn-primary" >Confirmar</button>
      </div>
    </div>
  </div>
</div>









    </div>
  );
};

export default Addpelicula;