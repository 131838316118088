import React, { useState } from 'react';

const ImageWithPreloader = ({ src, alt }) => {
  const [loading, setLoading] = useState(true);

  const handleImageLoaded = () => {
    setLoading(false);
  };

  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      {loading && (
        <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
<i style={{color:"white"}} className="fas fa-spinner fa-spin" />
        </div>
      )}
      <img
        src={src}
        alt={alt}
        style={{ display: loading ? 'none' : 'block', width: '100%', height: '100%' }}
        onLoad={handleImageLoaded}
      />
    </div>
  );
};

export default ImageWithPreloader;