import React from 'react';
import './home.component.css';
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <div className='container my-5 p-10'>
             <div className="hero__bg__overlay" />

       <Link to={"/login"} className="float-right">    <button className="signin__button">Login</button>                 
                </Link>

            <img src="../img/logo_escuela_aye_sd.png" alt="logo" className="container" />
            <br></br>
  
       <div class="alert alert-dark" role="alert">
  <h4 class="alert-heading">Lo siento</h4>
  <p>Este enlace de votaciones ha caducado. Puedes intentar ingresar desde tu correo Electrónico</p>
</div>
    </div>
  );
};

export default Home;