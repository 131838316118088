import React from 'react';
import IconCross from '../Icons/IconCross';
import './Content.scss';

const Sobre = ({ comp }) => (
  <div className="content">
      <div
      className="content__background__image"
      style={{
        backgroundImage: `url(${comp.imgportada})`,
        position: 'relative',
      }}
    >
      <div
        className="gradient-overlay"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundImage: 'linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0))',
        }}
      />
      
      </div>
    <div className="content__area">
      <div className="content__area__container">
        <div className="content__title py-5">{comp.titulo}</div>
        <div className="content__description">
         {comp.resumen}
        </div>
      </div>
     
    </div>
  </div>
);

export default Sobre;
