import React, { Component } from "react";
import { Navigate,Link } from "react-router-dom";
import UserService from "../services/user.service";
import authService from "../services/auth.service";
import "./styles.profile.css";
import Pagar from "../components/pagar.component"
import Modal from 'react-modal';
import CarruselNetflix from "./carruselnetflix.component";
import Paneladm from "../Paneladm";
import {  redirect } from "react-router-dom";
import { Routes, Route, Outlet } from "react-router-dom";



export default class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: null,
      userReady: false,
      currentUser: null,
      content:null,
      contenido:null,
      mensajes:null,
      novedades:[],
      exclusivo:[{titulo:"Realizacion de Casting",descripcion:"Nuevo casting para actores y demas bal bla bla",fechainicio:"",img:"images/1.jpeg"},{titulo:"Estudios Integrales de Cine y television",descripcion:"Un nuevo curso esta por empezar",fechainicio:"",img:"images/1.jpeg"},{titulo:"Inaugurado nuevo espacio",descripcion:"resumen",fechainicio:"",img:"images/1.jpeg"},{titulo:"hola",descripcion:"resumen",fechainicio:"",img:"images/1.jpeg"}],
trabajos:[],
     modalVideo:null,
     modalmensajes:false,
      seleccion:null,
      tiempo:1,
      errores:null,
      home:this.props.home,
      agrupados:[],
      pagina:0

    };
  }


contarydividir(data){



  const array = data;
const size = 2;
const result = [];


for (let i = 0; i < Math.ceil(array.length / size); i++) {
 
  result[i] =array.slice(i * size, i * size + size);



}

return result


}


mostrargrupo(data,pag){

let datos = this.contarydividir(data)

console.log("grupoarmado:",datos)

   
let componente = datos.map((item,index)=> <div class="media-group" id={"group-"+pag} >

{item.map((elemento)=> <div class="media-element">
   <img     src={elemento.img}
     alt=""></img>
 </div>)}

 <a class="next"  aria-label="next" onClick={()=>this.setState({pagina:1})}>
   <svg>
     <use ></use>
   </svg>
 </a>
</div>)

 console.log()

 return componente






}



 carruselcontenido(data){


//agrupar cantidad especificada


   
 let componente = data.map((item,index)=> <div class="media-group" id={"group-"+index} >

{item.grupo.map((item)=> <div class="media-element">
   <img     src={item.img}
     alt=""></img>
 </div>)}

 <a class="next"  aria-label="next">
   <svg>
     <use href="#next"></use>
   </svg>
 </a>
</div>)

 console.log()

 return componente

 }



  componentDidMount() {


    //utiliza el token para conseguir datos de usuario
    UserService.getUserBoard().then(
      response => {
        this.setState({
          currentUser: response.data,
          userReady: true,
          mensajes: response.data.notificacionusuario

        });

        console.log("en perfile",response.status)

        if (response.status === 401){

          <Navigate to={"/"} />

        }

      },
      error => {

//si no lo consigues, solicita login



        this.setState({
          userReady: false,
          errores:
            (error.response && error.response.data) ||
            error.message ||
            error.toString()
        });

   <Navigate to={"/"} />

      }
    ).catch(()=>{


      return <Navigate to={"/"} />


    }
    
    
    
    );
   

  
  }

  render(){





    
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />
    }

        
    const { currentUser,seleccion,mensajes,home,userReady } = this.state;

    return (
      <>
     <div>

  <div className="navbar2">
    <div className="navbar2-container">
      <div className="logo2-container">

      <Link to={"/home"} >

        <img src="../img/logo_escuela_aye_sd.png" className="logo2" alt="Escuela de Cine" />                </Link>

      </div>
      <div className="menu2-container">
        <ul className="menu2-list">

          <li className="menu2-list-item active"><i className="fa fa-home" aria-hidden="true"></i> Inicio</li>
        {currentUser && currentUser.nivel =="adm" && <>
          <Link className="menu2-list-item active" to={"send"}   state={this.state.currentUser} style={{color:"white"}} >
           <li className=""><i className="fa fa-envelope-o" aria-hidden="true"></i> Envios</li></Link> 
           
           <Link className="menu2-list-item active" to={"addcomp"} style={{color:"white"}} >
          <li ><i className="fa fa-university" aria-hidden="true"></i> Competencias</li></Link> 

          <Link className="menu2-list-item active" to={"addpeliculas"} style={{color:"white"}} >
          <li ><i className="fa fa-film" aria-hidden="true"></i> Peliculas</li></Link> 


          <Link className="menu2-list-item active" to={"miembros"} style={{color:"white"}} >
          <li ><i className="fa  fa-address-book" aria-hidden="true"></i> Miembros</li></Link> 

          <Link className="menu2-list-item active" to={"miembros"} style={{color:"white"}} >
          <li ><i className="fa  fa-money" aria-hidden="true"></i> Contribuciones</li></Link> 
          </>
          }
{/*           <li className="menu2-list-item">Cursos</li>
 */}

   {/*        
          <li className="menu2-list-item">Novedades</li>
          <li className="menu2-list-item">Filmmaker</li>
 */}

          
        </ul>
      </div>
      
      {this.state.currentUser? <div className="profile2-container">
      <div className="profile2-text-container">
          <span className="profile2-text">{this.state.currentUser.name}</span>

     
        </div>
                <button  className="btn btn-primary" onClick={()=> this.setState({modalmensajes:true})}><i className="fa fa-envelope" /> {this.state.mensajes.length}</button>
                <Link to={"/pagos"} className="nav-link">  
        <button  className=" btn btn-info  mx-1"><i className="fas fa-shopping-cart" /> </button>
        </Link>

        <button onClick={()=>{authService.logout() ; this.setState({redirect:"/home",currentUser:undefined,userReady:false});
}} className=" btn btn-danger "><i className=" fa fa-sign-out" aria-hidden="true"></i></button>

        
      </div>:<div className="nav__item">
        <Link to={"/login"} className="nav-link">    <button className="signin__button">Login</button>                 
                </Link>



        </div>}
    </div>
  </div>
  {/* Sidebar Section */}
  <div className="sidebar2">
 {/*    
    <i className="left-menu-icon fas fa-search" /> */}
     <Link to={"home"} className="nav-link">  
    <i className="left-menu-icon fas fa-home" />
</Link>

<Link  to={"send"} className="nav-link">  

<i className="left-menu-icon fa fa-envelope-o" aria-hidden="true"></i> 
</Link>


<Link to={"addcomp"} className="nav-link">  
<i className="left-menu-icon fa fa-university" aria-hidden="true"></i>
</Link>


<Link to={"addpeliculas"} className="nav-link">  
<i className="left-menu-icon fa fa-film" aria-hidden="true"></i>
</Link>

<Link to={"miembros"} className="nav-link">  
<i className="left-menu-icon  fa fa-address-book" />
</Link>
 
  
{/*     <i className="left-menu-icon fa fa-info-circle" />
    <i className="left-menu-icon fas fa-users" />
     */}

{userReady &&   <Link to={"/pagos"} className="nav-link">  
    <i className="left-menu-icon fas fa-shopping-cart" />
  </Link>
  }
  
  </div>
 

  <Outlet />


    </div>



      </>
    );
  }
}
