import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import AuthService from "../services/auth.service";
import "./styles.component.css";
import { Routes, Route, Link } from "react-router-dom";


import { withRouter } from '../common/with-router';

const required = value => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
Este campo es requerido      </div>
    );
  }
};

class Login extends Component {
  constructor(props) {
    super(props);
    this.handleLogin = this.handleLogin.bind(this);
    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);

    this.state = {
      username: "",
      password: "",
      loading: false,
      message: "",

      redirect: null,
      userReady: false,
      currentUser: null,
      content:null

    };
  }





  onChangeUsername(e) {
    this.setState({
      username: e.target.value
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value
    });
  }

  handleLogin(e) {
    e.preventDefault();
    this.setState({ message: "", loading: true });

    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
        AuthService.login(this.state.username, this.state.password)
            .then(response => {
                const { status } = response;

                  console.log(status)

                if (status) {
                    this.props.router.navigate("/profile");
                    window.location.reload();
                } else {
                    this.setState({ loading: false, message: "Nombre de usuario o contraseña incorrectos" });
                }
            })
            .catch(error => {
                const resMessage = error.response?.data?.error || "Error desconocido";
                this.setState({ loading: false, message: resMessage });
            });
    } else {
        this.setState({ loading: false });
    }
}

authHeader() {
    const user = JSON.parse(localStorage.getItem('user'));

    if (user && user.accessToken) {
        return { Authorization: 'Bearer ' + user.accessToken }; // Asegúrate de que el nombre del token sea correcto
    } else {
        return {};
    }
}

  render() {
    return (
      <div className="hero">
  <header>


      <div className="navbar__nav__items">
        <div className="nav__item">
      
    
        </div>

      </div>
  </header>
        
  <br></br>
      
      <div className="hero__bg__overlay" />
       
      
        <div className="card card-container text-white">

          <img
            src="../img/logo_escuela_aye_sd.png"
            alt="profile-img"
            className=""
          />        <h3>Login Acceso</h3>

          <Form
            onSubmit={this.handleLogin}
            ref={c => {
              this.form = c;
            }}
          >
            <div className="form-group">
              <label htmlFor="username">Correo Electrónico</label>
              <Input
                type="text"
                className="form-control"
                name="username"
                value={this.state.username}
                onChange={this.onChangeUsername}
                validations={[required]}
              />
            </div>

            <div className="form-group">
              <label htmlFor="password">Contraseña</label>
              <Input
                type="password"
                className="form-control"
                name="password"
                value={this.state.password}
                onChange={this.onChangePassword}
                validations={[required]}
              />
            </div>

            <div className="form-group">
              <button
                className="btn btn-primary btn-block"
                disabled={this.state.loading}
              >
                {this.state.loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                <span>Ingresar</span>
              </button>
            </div>

            {this.state.message && (
              <div className="form-group">
                <div className="alert alert-danger" role="alert">
                  {this.state.message}
                </div>
              </div>
            )}
            <CheckButton
              style={{ display: "none" }}
              ref={c => {
                this.checkBtn = c;
              }}
            />
            <Link to={"/register"} className="nav-link container">
                  ¿Aún no te has Registrado?
                </Link>
          </Form>
        </div>
      


    </div>
    );
  }
}

export default withRouter(Login);