import React, { useState, useEffect } from 'react';
import UserService from '../../services/user.service';
import { update } from 'lodash';
import userService from '../../services/user.service';

const AddCompetencia = () => {
  const [formData, setFormData] = useState({
    titulo: '',
    inicio: '',
    cierre: '',
    estado: '',
    resumen: '',
    descripcion: '',
    bases: '',
    adjunto: null,
    imgportada: null,
    imgpie: null,
    tipo: ''
  });

  const [competencias, setCompetencias] = useState([]);
  const [consulta, setConsulta] = useState(null);
  const [loading, setLoading] = useState(false);
  const [editar, seteditar] = useState(false);

  const [addnew, setaddnew] = useState(false);

  const handleEstadoChange = async (e) => {
    try {
      const nuevoEstado = e.target.value;

      const data = { estado: nuevoEstado };
  
      const response = await UserService.editcomp(consulta.competencia.id, data);
      
      // No necesitas llamar a response.json(), ya que Axios lo maneja automáticamente
      const result = response.data;
  
      // Axios lanza un error si la respuesta no es exitosa, así que no necesitas este chequeo
      setConsulta((prevState) => ({
        ...prevState,
        competencia: {
          ...prevState.competencia,
          estado: nuevoEstado,
        },
      }));
      console.log('Estado de la competencia actualizado exitosamente');
    } catch (error) {
      console.error('Error al actualizar el estado de la competencia:', error.response?.data || error.message);
    }
  };





  const updateForm = async (e) => {
    try {

      e.preventDefault();

      const response = await UserService.editcomp(consulta.competencia.id, formData);
      
      // No necesitas llamar a response.json(), ya que Axios lo maneja automáticamente
      const result = response.data;
  
 
      console.log('competencia actualizado exitosamente');
      window.location.reload(false)
    } catch (error) {
      console.error('Error al actualizar la competencia:', error.response?.data || error.message);
    }
  };









  const retirardecomp = (idcompetencia, idpelicula) => {
    if (window.confirm('¿Estás seguro de que deseas retirar esta película de la competencia?')) {
      setLoading(true);

      return userService.retirar(idcompetencia, idpelicula)
        .then(() => {
          alert("La película se ha retirado de esta competencia");
          setLoading(false);
          window.location.reload(false)

        })
        .catch((error) => {
          console.error('Error al retirar la película:', error);
          alert('No se pudo retirar la película. Inténtalo de nuevo.');
        });
    }
  };



  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.files[0]
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();

    // Agregar datos del formulario a FormData
    Object.keys(formData).forEach(key => {
      formDataToSend.append(key, formData[key]);
    });
    console.log('Datos del formulario:', formData);


    try {
      await UserService.addcompetencia(formDataToSend);
      alert('Competencia creada exitosamente');
      window.location.reload(false)

      setFormData({
        titulo: '',
        inicio: '',
        cierre: '',
        estado: '',
        resumen: '',
        descripcion: '',
        bases: '',
        adjunto: null,
        imgportada: null,
        imgpie: null,
        tipo: ''
      });
    } catch (error) {
      console.error(error);
      alert('Error al crear la competencia');
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0]; // Devuelve solo la parte de la fecha
  };

  const consultarVotos = async (id) => {
    setLoading(true);
    try {
      const response = await UserService.getShowvotos(id);
      setConsulta(response.data);
console.log(response.data)
    } catch (error) {
      console.error(error);
      alert('Error al consultar los votos');
    } finally {
      setLoading(false);
    }
  };


  

  useEffect(() => {
    const fetchCompetencias = async () => {
      try {
        const response = await UserService.getPublicVotaciones();
        setCompetencias(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchCompetencias();
  }, []);

  return (
    <div className="container">
        
      

                  {addnew ||editar?

        <div className="container">
          <div className="card shadow">
          <div className="card-header bg-secondary text-white">
              <h2 className="mb-0">Formulario:</h2>
            </div>
            <br></br>
            <div className="card-body text-white">
              
              <form onSubmit={editar? updateForm: handleSubmit}>
                <div className="form-group">
                  <label htmlFor="titulo">Título de Competencia:</label>
                  <input
                    type="text"
                    id="titulo"
                    name="titulo"
                    className="form-control"
                    value={formData.titulo}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="inicio">Inicio Votaciones:</label>
                  <input
                    type="date"
                    id="inicio"
                    name="inicio"
                    className="form-control"
                    value={formData.inicio}
                    onChange={handleInputChange}
                    
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="cierre">Cierre Votaciones:</label>
                  <input
                    type="date"
                    id="cierre"
                    name="cierre"
                    className="form-control"
                    value={formData.cierre}
                    onChange={handleInputChange}
                    
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="estado">Estado:</label>
                  <select
                    id="estado"
                    name="estado"
                    className="form-control"
                    value={formData.estado}
                    onChange={handleInputChange}
                    
                  >
                    <option value="">Seleccione un estado</option>
                    <option value="publico">Publico</option>
                    <option value="oculto">Oculto</option>
                    <option value="cerrado">Cerrado</option>

                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="resumen">Resumen de Competencia:</label>
                  <textarea
                    id="resumen"
                    name="resumen"
                    className="form-control"
                    value={formData.resumen}
                    onChange={handleInputChange}
                    
                  ></textarea>
                </div>
                <div className="form-group">
                  <label htmlFor="descripcion">Descripción de Competencia:</label>
                  <textarea
                    id="descripcion"
                    name="descripcion"
                    className="form-control"
                    value={formData.descripcion}
                    onChange={handleInputChange}
                    
                  ></textarea>
                </div>
                <div className="form-group">
                  <label htmlFor="bases">Bases de la Competencia:</label>
                  <textarea
                    id="bases"
                    name="bases"
                    className="form-control"
                    value={formData.bases}
                    onChange={handleInputChange}
                    
                  ></textarea>
                </div>
                
                <div className="form-group">
                  <label htmlFor="tipo">Tipo:</label>
                  <input
                    type="text"
                    id="tipo"
                    name="tipo"
                    className="form-control"
                    value={formData.tipo}
                    onChange={handleInputChange}
                    
                  />
                </div>

{!editar &&
                <div>
                <div className="form-group">
                  <label htmlFor="adjunto">Documento adjunto (PDF):</label>
                  <input
                    type="file"
                    id="adjunto"
                    name="adjunto"
                    className="form-control-file"
                    onChange={handleFileChange}
                    
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="imgportada">Imagen de Portada:</label>
                  <input
                    type="file"
                    id="imgportada"
                    name="imgportada"
                    className="form-control-file"
                    onChange={handleFileChange}
                    
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="imgpie">Imagen Pie de pagina:</label>
                  <input
                    type="file"
                    id="imgpie"
                    name="imgpie"
                    className="form-control-file"
                    onChange={handleFileChange}
                    
                  />
                </div>
                </div>
}

                <button type="submit" className="btn btn-primary"><i className="fa fa-check" aria-hidden="true"></i><span> </span> {editar?"Editar":"Registrar"}</button>
               <span> </span> <button onClick={()=> {  window.location.reload(false)}} className="btn btn-danger"> <i className="fa fa-close" aria-hidden="true"></i><span> </span>Cancelar</button>

              </form>
            </div>
          </div>
        </div>:  <div className="container">
          <div className="card shadow">
            <div className="card-header bg-secondary text-white">
              <h2 className="mb-0">Competencias:</h2>
            </div>
            
            <div className="card-body">
            <button className="btn btn-primary"  onClick={()=> setaddnew(true)} ><i className="fa fa-plus" aria-hidden="true"></i><span> </span>
            Agregar Nuevo                </button>
              <div className="form-group">
                <label htmlFor="comp">Selecciona una Competencia:</label>
                <select
                  id="comp"
                  className="form-control"
                  onChange={(e) => consultarVotos(e.target.value)}
                >
                  <option value="">Seleccione una competencia</option>
                  {competencias.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.titulo}
                    </option>
                  ))}
                </select>
              </div>
              {loading && <p>Cargando resultados...</p>}
              {consulta && (
                <div className=" card-body form-group bg-secondary text-white">
                                  <h3 >Datos Competencia</h3>
                    <div >
                    <p >{consulta.competencia.titulo}</p>

                  
<strong>Inicio Votaciones:</strong>

<p>{formatDate(consulta.competencia.inicio)}</p>
<strong>Cierre Votaciones:</strong>

<p>{formatDate(consulta.competencia.cierre)}</p>
<label htmlFor="estado"><strong>Estado Actual:</strong></label>
                  <select
  id="estado"
  name="estado"
  className="form-control"
  value={consulta.competencia.estado}
  onChange={handleEstadoChange}
>
  <option value="publico">Publico</option>
  <option value="oculto">Oculto</option>
  <option value="cerrado">Cerrado</option>
</select>

<div>
  <br></br>
<button   className=" btn btn-primary " onClick={()=>{setFormData(consulta.competencia);seteditar(true)}}
><i className="fa fa-pencil" aria-hidden="true"></i><span> </span>
Editar Competencia</button>
</div>
                </div>
                <br></br>
                <h3 className='card-header bg-secondary text-white py-10'>Resultados:</h3>

                                    <table className="table table-dark ">
  <thead>
    <tr>
      <th>Posición</th>
      <th>Pelicula</th>
      <th>Votos Recibidos</th>
      <th>Acciones</th>

    </tr>
  </thead>
  <tbody>
    {consulta.conteo_peliculas.map((item, index) => (
      <tr key={index}>
        <td># {index + 1}</td>
        <td>{item.tituloes}</td>
        <td> [<strong> {item.conteo}</strong> ] Votos 
        </td>
        <td><button className='btn btn-danger' onClick={()=>{retirardecomp({idcompetencia:consulta.id,idpelicula:item.id}) }}> <i className="fa fa-trash" aria-hidden="true"></i>
        </button> 
        </td>
      </tr>
    ))}
    <tr>
      <td colSpan="3" className="text-center">
        <strong>Total de Votos Realizados:</strong> {consulta.total_votos}
        <button   className=" btn btn-info float-right" onClick={()=>alert("En construccion")}
><i className="fa fa-search " aria-hidden="true"></i><span> </span>
Auditar Votos</button>


      </td>
    </tr>
  </tbody>

</table>
<div className='container'>
            <h2>Lista de Votantes</h2>
            <ol>
                {consulta.votantes.map((votante, index) => (
                    <li key={index}> 
                        {votante.name} {votante.apellidos}
                    </li>
                ))}
            </ol>
        </div>
</div>

              )}
            </div>
          </div>
        </div>
         }
    </div>
  );
};

export default AddCompetencia;