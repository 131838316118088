import React, { useState } from 'react';
import IconCross from './../Icons/IconCross';
import './Content.scss';
import Modal from 'react-modal';
import UserService from '../../services/user.service';


const Content = ({ movie, onClose , comp }) => {
  const [isLiked, setIsLiked] = useState(false);
  const [trailer, setTrailer] = useState(null);
  const [vimeo, setVimeo] = useState(null);

  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [user, setUser] = useState(comp);
  const [votoya, setVotoya] = useState(false);


  
 const enviarvoto = ()=>{

  let voto = {userid:user.uuid, idcompetencia:user.id, idpelicula:movie.id,ronda:1}


    UserService.enviarvoto(voto
  ).then(
    response => {

 setModal2(false)
setVotoya(true)

window.location.reload(); // Esto recargará la página


    },
    error => {

      console.log(voto)
      alert(error.mensaje)

    }
  );

};
  
 













const estiloTexto = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    padding: '10px',
    borderRadius: '5px',
  };





  return (
    <div className="content">
      <div
      className="content__background__image"
      style={{
        backgroundImage: `url(${movie.imagenes || movie.imgportada})`,
        position: 'relative',
      }}
    >
      <div
        className="gradient-overlay"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundImage: 'linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0))',
        }}
      />
      
      </div>
      <div className="content__area">
        <div className="content__area__container">
          <div className="movie-card ">
            <h2 className="title">{movie.tituloes}</h2>
            <h3 className="title">{movie.tituloeng}</h3>
          <div className='scrollable-text'>
            <p className="synopsis">{movie.sinopsis}</p>
          </div>
            <p>
              <button className="btn btn-outline-info btn-sm mx-1" disabled>
                Tipo: {movie.censuratipo}
              </button>
            </p>
            <h4>Reparto:</h4>
            <p className='col-lg-6'>{movie.reparto}</p>
            <h4>Equipo:</h4>
            <strong>Dirección:</strong> {movie.director}<br/>
            <strong>Guion:</strong> {movie.guionista}<br/>
            <strong>Producción:</strong> {movie.produccion}<br/>
            <p></p>
            <div className="">
              <div className="btn-group" role="group" aria-label="Basic example">
                <button type="button" className="btn btn-secondary" onClick={() => setTrailer(movie.linktrailer)} >Trailer <i className="fa fa-play" aria-hidden="true" /></button>
                <button type="button" className="btn btn-secondary" onClick={() =>{movie.linkpelicula? setVimeo(movie.linkpelicula): setModal2(true)}}  >Pelicula <i className="fa fa-play" aria-hidden="true" /></button>
                
                {user.yavoto ==false && user.habilitar ==true ?<button className="btn btn-primary btn-lg col-12 py-2" onClick={() => setModal(true)}>
                  Votar <span> </span><i className="fa fa-thumbs-up" aria-hidden="true" />

                </button>:<button className="btn btn-dark btn-lg col-12 py-2" disabled="true">
               <p className='col-12'> {user.yavoto ==true? "Gracias por participar" : user.mensaje_votaciones} <span> </span><i className="fa fa-calendar" aria-hidden="true" /></p>

                </button>}

<br></br>
              </div>
              <br></br>

            </div>
            <br></br>

          </div>
          <br></br>

        </div>
      </div>



      {trailer &&   <div  className="videoyoutube"> 
  
  <iframe title={movie.titulo} className="videoyoutube" src={'https://www.youtube.com/embed/'+trailer+'?mute=0&autoplay=1&loop=1&controls=1'} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture;loop" allowfullscreen></iframe>
  <div className="detallesvideo">
  <h3 className="mx-2 my-2">{movie.tituloes} </h3>
  <p className="mx-2 col-10">{movie.sinopsis} </p>
  <button onClick={()=>setTrailer(null)} className="btn btn-danger  cerravideo "><i className="fa fa-sign-out" aria-hidden="true" />
</button>
  
  </div>
  </div>}

  {vimeo &&   <div  className="videoyoutube"> 
 {/*  
  <iframe title={movie.titulo} className="videoyoutube" src={'https://www.youtube.com/embed/'+trailer+'?mute=0&autoplay=1&loop=1&controls=1'} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture;loop" allowfullscreen></iframe>
   */}
<iframe  className="videoyoutube" src={'https://player.vimeo.com/video/'+vimeo+'?badge=0&autopause=1&player_id=0&app_id=58479'} frameBorder={0} allow="autoplay; fullscreen; picture-in-picture; clipboard-write" style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}} title={movie.tituloes} />

  <div className="detallesvideo">
<h3 className="mx-2 my-2">{movie.tituloes}   <a  className='h5'  onClick={()=>   { navigator.clipboard.writeText(movie.password);alert("Ahora puedes pegar la contraseña")}}> Contraseña<strong> <i className="fa-solid fa-copy"></i> </strong></a></h3>
  <p className="mx-2 col-10">{movie.sinopsis} </p>
  <button onClick={()=>setVimeo(null)} className="btn btn-danger  cerravideo "><i className="fa fa-sign-out" aria-hidden="true" />
</button>
  
  </div>
  </div>}

  <Modal  className='modalosafiches'
  isOpen={modal}
  shouldCloseOnOverlayClick={true}
  ariaHideApp={false}
  style={{
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      position: 'fixed',
      top: 0,
      left: 0,
  
      width: '100vw', // Ancho al 100% de la ventana del navegador
      height: '100vh', // Alto al 100% de la ventana del navegador
      zIndex: 9999
    },
    content: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100vw', // Ancho al 100% de la ventana del navegador
      height: '100vh', // Alto al 100% de la ventana del navegador
      color: 'white',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      
    
    }
  }}
  contentLabel="Example Modal"
>
  <div >
    <div className='px-1' >
      <br></br>
    <h3>Estás a punto de enviar tu Voto por la Película:</h3>
    <h2 className="title" style={{color:'yellow'}}>{movie.tituloes}</h2>
    ¿Estás seguro de tu elección?
    <p>
      <button className="btn btn-danger btn-lg " onClick={() => setModal(false)}><i className="fa fa-thumbs-down" aria-hidden="true" /> No, Salir </button><span> | </span>
      <button className="btn btn-primary btn-lg " onClick={() => {enviarvoto(); setModal(false)}}>Si, Votar <i className="fa fa-thumbs-up" aria-hidden="true" /></button>
    </p>
    <br></br>

    </div>
  </div>
</Modal>

<Modal  className='modalosafiches'
  isOpen={modal2}
  shouldCloseOnOverlayClick={true}
  ariaHideApp={false}
  style={{
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      position: 'fixed',
      top: 0,
      left: 0,
  
      width: '100vw', // Ancho al 100% de la ventana del navegador
      height: '100vh', // Alto al 100% de la ventana del navegador
      zIndex: 9999
    },
    content: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100vw', // Ancho al 100% de la ventana del navegador
      height: '100vh', // Alto al 100% de la ventana del navegador
      color: 'white',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      
    
    }
  }}
  contentLabel="Example Modal"
>
  <div >
    <div className='px-1' >
      <br></br>
    <h3>Para ver la Película:</h3>
    <h2 className="title" style={{color:'yellow'}}>{movie.tituloes}</h2>
    <p> Debes ingresar con tu cuenta a la pagina de VEOACACV, te recordamos que tu codigo de acceso: <strong onClick={()=>   { navigator.clipboard.writeText(user.veoacacv);alert("Ahora puedes pegar la contraseña en la pagina VEOACACV")}
}> <button className="btn btn-info"> Copiar Codigo<i className="fa-solid fa-copy"></i></button> </strong></p>
    <br></br>

    <p>
   <a className='btn btn-primary py-2' href="https://veoacacv.com" target="_blank"  >Ingresar a VEOACACV <i className="fa fa-play" aria-hidden="true"></i> </a> 
   
    </p>

<button className='btn btn-danger' onClick={() => setModal2(false)} > Regresar <i className="fa fa-undo" aria-hidden="true"></i>
</button>
    </div>
  </div>
</Modal>




    </div>
  );
};

export default Content;