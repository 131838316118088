import React, { useState, useEffect } from 'react';
import UserService from '../../services/user.service';
import { update } from 'lodash';
import userService from '../../services/user.service';

const Addmiembros = () => {
  const [formData, setFormData] = useState({
    titulo: '',
    inicio: '',
    cierre: '',
    estado: '',
    resumen: '',
    descripcion: '',
    bases: '',
    adjunto: null,
    imgportada: null,
    imgpie: null,
    tipo: ''
  });

  const [competencias, setCompetencias] = useState([]);
  const [consulta, setConsulta] = useState(null);
  const [loading, setLoading] = useState(false);
  const [editar, seteditar] = useState(false);
  const [miembros, setmiembros] = useState([]);

 
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredMembers, setFilteredMembers] = useState(miembros);

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      const filtered = miembros.filter((miembro) =>
        `${miembro.name} ${miembro.apellidos}`.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredMembers(filtered);



      const fetchMiembros = async () => {
        try {
          const response = await UserService.allmiembros();
          setmiembros(response.data);
        } catch (error) {
          console.error(error);
        }
      };
  
      fetchMiembros();



    }, 300); // 300ms delay

    return () => clearTimeout(debounceTimeout);
  }, [searchTerm, miembros]);

  const scrollToMember = (id) => {
    const memberElement = document.getElementById(`member-${id}`);
    if (memberElement) {
      memberElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="container mt-5">
      <div className="input-group mb-3">
        <input
          type="text"
          className="form-control"
          placeholder="Buscar miembro por nombre o apellido..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      <div className="row">
        {filteredMembers.length > 0 ? (
          filteredMembers.map((miembro) => (
            <div className="col-md-4 mb-3" key={miembro.id}>
              <div className="card bg-dark text-white" id={`member-${miembro.id}`}>
                <div className="card-body p-3">
                  <h5 className="card-title">{miembro.name} {miembro.apellidos}</h5>
                  <p className="card-text mb-1"><strong>Cédula:</strong> {miembro.nacionalidad}{miembro.cedula}</p>
                  <p className="card-text mb-1"><strong>Teléfono:</strong> {miembro.telefono}</p>
                  <p className="card-text mb-1"><strong>Email:</strong> {miembro.email}</p>
                  <p className="card-text mb-1"><strong>Especialidad:</strong> {miembro.especialidad}</p>
                  <p className="card-text mb-1"><strong>Especialidad 2:</strong> {miembro.especialidad2}</p>

                  <p className="card-text mb-1"><strong>Veoacacv:</strong> {miembro.veoacacv}</p>
                  <p className="card-text mb-1"><strong>M.Numerario:</strong> {miembro.miembro_numerario}</p>
                  <p className="card-text mb-1"><strong>M.Honorario:</strong> {miembro.miembro_honorario}</p>
                  <p className="card-text mb-1"><strong>Estatus:</strong> {miembro.estatus}</p>
                  <button className="btn btn-primary" onClick={() => scrollToMember(miembro.id)}>
                    Ver Ficha
                  </button>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="col-12">
            <div className="alert alert-warning" role="alert">
              No se encontraron resultados.
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Addmiembros;