import React, { useState, useEffect } from 'react';
import UserService from '../../services/user.service';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import { Link } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useLocation ,Navigate} from 'react-router-dom';

const Enviarcorreos = () => {

  const location = useLocation();
  const usuario  =location.state ; // Aquí obtienes los datos

    // Si no hay usuario, redirige a otra página
  


  const [formData, setFormData] = useState({
    email: '',
    uuid: '',

    comp: 0,
    mensaje: '',
    asunto: '',
    uuid:  ''
  });


  

  const [botonvotar, setBotonvotar] = useState(false);
  const [competencias, setCompetencias] = useState([]);
  const [confirmaciones, setConfirmaciones] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);

  const [enviados, setEnviados] = useState(false);
  const [visitas , setVisitas] = useState([]);


  const [selectedMembers, setSelectedMembers] = useState({});

  const [destinos, setDestinos] = useState([]);

  const [miembros, setmiembros] = useState([]);

  const membersData = miembros




  function MemberTable() {
  
    const handleSelectAll = (event) => {
      const isChecked = event.target.checked;
      const newSelectedMembers = {};
      membersData.forEach(member => {
        newSelectedMembers[member.id] = isChecked;
      });
      setSelectedMembers(newSelectedMembers);

    };
  
    const handleSelectMember = (id) => {
      setSelectedMembers(prev => ({
        ...prev,
        [id]: !prev[id],
      }));

    };
  
    const getSelectedEmails = () => {
      return membersData
        .filter(member => selectedMembers[member.id])
        .map(member => member.uuid);
    };
  
    return (
      <div className="col-11 mt-4">
       
      <div className="table-responsive" style={{ maxHeight: '400px', overflowY: 'auto' }}>
        <table className="table table-bordered">
          <thead className="thead-light">
            <tr>
              <th>
                <input
                  type="checkbox"
                  onChange={handleSelectAll}
                />
                Seleccionar Todos
              </th>
            </tr>
            <tr>
              <th>Nombre</th>
            </tr>
          </thead>
          <tbody>
            {membersData.map(member => (
              <tr key={member.id}>
                <td>
                  <input
                    type="checkbox"
                    checked={!!selectedMembers[member.id]}
                    onChange={() => handleSelectMember(member.id)}
                  /> 
                  {` - ${member.name} ${member.apellidos} = ${member.email}`}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

      </div>
      <br></br>
      <button className='btn btn-primary' onClick={() => {setDestinos(getSelectedEmails());setShowModal2(false)}}>
Confirmar Seleccion      </button>
<br></br>
      <br></br>

    </div>
    );
  }











  const handleEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState);
    const content = newEditorState.getCurrentContent();
    const mensaje = draftToHtml(convertToRaw(content));
    setFormData({
      ...formData,
      mensaje: mensaje
    });
  };

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleCompChange = (e) => {
    setFormData({
      ...formData,
      comp: Number(e.target.value) // Asegúrate de que sea un número
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (destinos && formData.mensaje) {
      setShowModal(true);
    } else {
      alert("Verifica que seleccionaste una competencia y que ingresaste correctamente el resto de la información");
    }
  };

  const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

  const handleConfirm = async () => {
      setShowModal(false);
      setConfirmaciones([]);
      const emails = destinos;
      setLoading(true);
  
      for (const uuid of emails) {
          try {
              const response = await enviarlink({ ...formData, uuid });
              setConfirmaciones(prev => [...prev, "Enviando a: " + response.data.message]);
          } catch (error) {
              console.error(error);
              setConfirmaciones(prev => [...prev, "ERROR: No se envió el mensaje a " + uuid + " -- " + error.response.data.message]);
          }
  
          // Espera 20 segundos antes de enviar el siguiente correo
          await sleep(20000); // 20000 ms = 20 segundos
      }
  
      setLoading(false);
  }

  const enviarlink = (data) => {
    return UserService.enviarlinkvotar(data);
  };

  useEffect(() => {
    UserService.getPublicVotaciones()
      .then(response => {
        console.log("competencias:", response.data);
        setCompetencias(response.data);
      })
      .catch(error => {
        console.error(error);
      });

      const fetchMiembros = async () => {
        try {
          const response = await UserService.allmiembros();
          setmiembros(response.data);
        } catch (error) {
          console.error(error);
        }
      };
  
      fetchMiembros();

      const fetchvisitas = async () => {
        try {
          const response = await UserService.getVisitas();

          console.log(response.data)
          setVisitas(response.data);
        } catch (error) {
          console.error(error);
        }
      };

      fetchvisitas();


  }, []);

  return (
    <div>
     <div className='d-flex justify-content-center w-75 p-3'>
     <button  onClick={()=>setEnviados(false)}  className='btn btn-info'>Redactar</button>     <button onClick={()=>setEnviados(true)} className='btn btn-primary'>Enviados</button>

     </div>
      {!enviados?
      <div className="container d-flex justify-content-center w-75 p-3">

<button onClick={()=>setShowModal2(true)} className='btn btn-primary'><i className="fa  fa-address-book" aria-hidden="true"></i>  Agregar Destinos</button>
        <form className="col-12 col-md-8 col-lg-8 bg-white p-4 rounded shadow" onSubmit={handleSubmit}>
          <h2 className="text-center mb-4">Enviar Correos</h2>
          <div className="form-group">
          
          </div>

          <div className="form-group">
            <label htmlFor="asunto">Asunto:</label>
            <input
              id="asunto"
              name="asunto"
              className="form-control"
              value={formData.asunto}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="mensaje">Mensaje:</label>
            <Editor
              className="form-control"
              editorState={editorState}
              onEditorStateChange={handleEditorStateChange}
              editorStyle={{
                minHeight: '300px',
                border: '1px solid #ced4da',
                padding: '10px',
                borderRadius: '0.25rem',
              }}
            />
          </div>

          {!botonvotar ?
            <button onClick={() => setBotonvotar(true)} className="btn btn-secondary"> + Votaciones</button>
            :
            <div className="form-group col-8 right">
              <label htmlFor="comp">Si desea agregar botón de votaciones debe seleccionar una competencia de lo contrario no se mostrará el botón en el correo:</label>
              <select
                defaultValue={0}
                id="comp"
                name="comp"
                className="form-control"
                value={formData.comp}
                onChange={handleCompChange} // Cambia a handleCompChange
                required
              >
                <option value={0}>Ninguna Competencia Seleccionada</option>
                {competencias.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.titulo}
                  </option>
                ))}
              </select>
              <p>Si no existe la competencia, debe crearla primero en la sección de <Link to={"/profile/addcomp"}>"Competencias"</Link> y agregar las películas participantes.</p>
            </div>
          }

          <br />
          <div className="text-center">
            <button type="submit" className="btn btn-primary" disabled={loading}>
              Enviar este Mensaje
            </button>
          </div>
          <br />
          {loading && <p className='btn btn-info disabled justify-content-center'>Enviando Mensajes...</p>}
        </form>
      </div>:
      <div className="container d-flex justify-content-center w-75 p-3">
        <div className='col-12 col-md-8 col-lg-8 bg-white p-4 rounded shadow'>
        <h2>Mensajes Enviados</h2>

        <div>
      <table className='table table-dark '>
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Email</th>
            <th>Competencia</th>

            <th>Visita</th>
            <th>Voto</th>
          </tr>
        </thead>
        <tbody>
          {visitas && visitas.map((item) => (
            <tr key={item.uuid}>
              <td>{item.name} {item.apellido}</td>
              <td>{item.email}</td>
              <td>{item.comp}</td>
              <td>{item.visita? "Si":"No"}</td>

            <td>{item.voto? "Si":"No"}</td>
              
            </tr>
          ))}
        </tbody>
      </table>
    </div>
      </div>
</div>
}
<div className="container">
    {confirmaciones.map((confirmacion, index) => (
        <div key={`confirmacion-${index}`} className="alert alert-info">
            <p>{confirmacion}</p>
        </div>
    ))}
</div>

    {/* Modal de Confirmación */}
<div className={`modal  ${showModal ? 'show' : ''}`} style={{ display: showModal ? 'block' : 'none' }} tabIndex="-1" role="dialog">
  <div className="modal-dialog" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">Confirmación de Envío</h5>
        <button type="button" className="close" onClick={() => setShowModal(false)}>
          <span>&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <p>Vista previa:</p>
        <p><strong>Asunto:</strong> {formData.asunto}</p>
        <p><strong>Mensaje:</strong></p>
        <div style={{ maxHeight: '300px', overflow: 'auto' }} dangerouslySetInnerHTML={{ __html: formData.mensaje }} />
        <p>
          <strong>Competencia:</strong>
          {formData.comp > 0
            ? competencias.find(comp => comp.id === formData.comp)?.titulo || 'No disponible'
            : ' No aplica'}
        </p>
      </div>
      <p className='container'>¿Estás seguro de que deseas enviar este mensaje?</p>

      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>Cancelar</button>
        <button type="button" className="btn btn-primary" onClick={handleConfirm}>Confirmar</button>
      </div>
    </div>
  </div>
</div>

    {/* Modal de Confirmación */}
    <div className={`modal  ${showModal2 ? 'show' : ''}`} style={{ display: showModal2 ? 'block' : 'none' }} tabIndex="-1" role="dialog">
  <div className="modal-dialog" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">Confirmación de Envío</h5>
        <button type="button" className="close" onClick={() => setShowModal2(false)}>
          <span>&times;</span>
        </button>
      </div>
      {          MemberTable()
      }  
   
    </div>
  </div>
</div>



    </div>
  );
};

export default Enviarcorreos;